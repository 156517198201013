<template>
  <ValidationProvider
    :rules="validationRules"
    class="question column is-half"
    tag="div"
    v-slot="{ errors, dirty, valid }"
  >
    <div class="question-text">
      <p class="help is-danger" v-if="errors[0]">{{ errors[0] }}</p>
      <p class="help" v-else>{{ questionHelp }}</p>
    </div>
    <div class="question-content card">
      <div class="card-content">
        <b-field
          :type="{ 'is-danger': errors[0], 'is-success': dirty && valid }"
        >
          <vue-dropzone
            :value="value[question.qid]"
            :destroyDropzone="true"
            :id="`downloadFileDropzone`"
            :options="dropzoneOptions"
            :useCustomSlot="true"
            @dropzone-error="onError"
            @dropzone-change="onChange"
            ref="file_upload"
          >
          </vue-dropzone>
        </b-field>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { QuestionMixin } from "@/mixins/question";
import Dropzone from "./shared/Dropzone.vue";
export default {
  name: "FileUpload",
  mixins: [QuestionMixin],
  components: {
    VueDropzone: Dropzone,
  },
  methods: {
    onError(file, message) {
      console.log(file, message);
    },
    onChange(data) {
      this.$emit("input", { [this.question.qid]: data });
    },
  },
  mounted() {
    this.$emit("mounted", "is-half");
  },
  computed: {
    dropzoneOptions() {
      return {
        params: {
          survey: this.$route.params?.code,
          questionCode: this.question?.code,
          rid: this.respondent?._id,
        },
        maxFiles: this.qOpts.max,
        acceptedFiles: this.qOpts.ext,
        maxFilesize: this.qOpts.size / 1024,
      };
    },
  },
};
</script>

<style lang="scss">
.vue-dropzone > .dz-preview .dz-remove {
  top: 5px;
  right: 5px;
  bottom: inherit;
}
</style>
